:root {
  --chat-row-template: 7.5rem 1fr 7.5rem;
  --room-link-label-font-size: 1.6rem;
  --composer-font-size: var(--room-link-label-font-size);
  --chat-line-font-size: var(--room-link-label-font-size);
  --chat-line-padding: 0.5rem 1rem;
}

@media (max-width: 960px) { /* Large Screens and below */
  :root {
    --chat-row-template: 5.5rem 1fr 7.5rem;
    --room-link-label-font-size: 1.4rem;
    --chat-line-padding: 0 1rem;
  }
}

/* Popup container - to display Room link is copied to clipboard */
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup .popuptext {
  visibility: hidden;
  width: 250px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 0.6rem;
  padding: 0.8rem 0;
  position: absolute;
  z-index: 1;
  bottom: -5rem;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -0.5rem;
  border-width: 0.5rem;
  border-style: solid;
  border-color: transparent transparent #000 transparent;
}

.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* Chat */
.chat {
  background: var(--color--tertiary);
  display: grid;
  grid-template-rows: var(--chat-row-template);
}

.chat__wrapper {
  overflow: hidden;
  overflow-y: auto;
  height: calc(100vh - 75px) !important;
}

.chat__wrapper .messages {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-family: 'Avenir' !important;
}
.chat__wrapper .messages p {   font-family: 'Avenir' !important;}
.chat__room-link {
  display: inline-flex;
  place-content: center;
  place-items: center;
}

.chat-line__link {
  color: var(--color-text-link);
  text-decoration: underline;
}

.room-link {
  background: var(--color-bg-input);
  padding: 0.5rem 1.5rem;
  border-radius: 40px;
  display: inline-flex;
  place-content: center;
  place-items: center;
}

.room-link__label {
  color: var(--color-text-base);
  font-size: var(--room-link-label-font-size);
  padding: 0 1rem 0 0;
}
/* .donate_popup {
  padding: 0;
  border-radius: 0;
  position: absolute;
  width: 85%;
  max-width: 520px;
  background-color: #fff;
  overflow: auto;
  max-height: 90vh;
}
.donate_popup .modal-head h2 {
  background-color: transparent!important;
  color: #393939;
  Padding: 15px!important;
  font-size: 1.2em;
} */
.chat-line {
  font-size: var(--chat-line-font-size);
  padding: var(--chat-line-padding);
}

.chime-web-composer {
  font-size: var(--composer-font-size);
  background: var(--color-bg-chat);
  padding: 1.5rem;
}

.bmpui-ui-watermark{
  display: none;
}

.flex_around{ display: flex; align-items: center; justify-content: space-between;}

.room-url{ width:100%; align-items: center; justify-content: space-between; border-radius:0!important;}

.flex_div{ display: flex; align-items: center;     justify-content: center;}

.room-link__label{ width:calc( 100% - 35px); text-overflow: ellipsis; overflow: hidden; white-space: normal; text-align: left; line-height: 1;; margin-bottom: 0;}

.chat_actions{  padding: 0.8rem 1rem; display: flex; align-items: center; justify-content: space-between; border-bottom: 1px solid #454545; background-color: #474747;}

.chat_actions i { font-size: 18px!important;}

.like_btns button{color: #f5f5f5;}
.like_btns button:hover{color: #ffffff;}
.like_btns button.active, .chat_button button.active{color: #2968f5;}

.commonbtn{    
   display: inline-flex;
  place-items: center;
  font-size: 1.2rem;
  height: auto;
  color:#fff;
  font-weight: var(--font-weight-bold);
  background: rgba(0,0,0,0.8);
  padding: 0.5rem 1rem;
  border-radius: var(--radius-small);}

  .commonbtn i{ font-size:18px!important}

  .chat_header{ width: 100%; height: auto;}


#SuccessAddPopup ,#ErrorPopup{
  margin-left: 0!important;
    z-index: 999!important;
}
.flex_display{ display: flex; align-items: center; }
.space_between{justify-content: space-between;}
.col5{flex: 0 0 20%; max-width: 20%; -webkit-box-flex: 0; -ms-flex: 0 0 20%; position: relative; min-height: 1px; padding-right: 15px; padding-left: 15px;}
.wp_card{ width:100%;}
.wp_card .wp_head{ padding:10px; }
.wp_card .wp_head{ max-width: 100%; max-height: 100%; display: block; text-align: center; margin:0 auto;}
.wp_title{ font-size: 18px; height: 50px;}
.search_block{ position: relative; }
.search_block i{ position: absolute; top:10px; left:10px; color:#eee;}
.search_block input{ padding-left:35px;}
.chatpropstabs{  padding-top: 25px;}
.chat_button { color: #b3b2b2; font-size: 12px; text-align: center;     letter-spacing: -0.5px;}
.chat_button button {  width: 100%;   height: 20px; color: #b3b2b2; margin-bottom: 5px; background: none;}
.donate {     height: 30px; display: flex; align-items: center; font-size: 12px; background-color: #3072ca; color: #fff; border-radius: 3px; padding: 3px 5px;}
/* .material-icons {  font-size: 14px;  margin-right: 5px;} */
.chat_info {  padding: 0 12px; }
.chat_info h2 { font-size: 16px; margin: 0 0 10px 0; font-weight: 600;}
.chat_info .sub_hed { font-size: 13px; color: #c8c8c8;}
.chat_info .sub_hed span { margin-right: 5px;}
.chat_info p { font-size: 14px;}
.activity .activity_hed { border-bottom: solid 1px #474747;  padding: 0 12px 10px 12px; width: 100%; display: flex; align-items: center; justify-content: space-between;}
.activity .activity_hed .hedText h3 { font-size: 14px; margin-bottom: 5px;}
.activity .activity_hed .hedText p { font-size: 14px; color: #c2c2c2; margin: 0px;}
.activity .activity_hed .hedText .material-icons { font-size: 20px; margin-right: 10px;}
.activity .activity_hed .wp_close { background:none; color: #989898; height: 30px;}
.activity .activity_hed .wp_close .material-icons { font-size: 34px; }
.activity .activity_content, .activity .voting_content{ padding: 12px 12px;}
.activity .activity_content ul { margin: 0px; padding: 0px;}
.activity .activity_content ul li { align-items: center; list-style: none; display: flex; width: 100%; background-color: #181818; padding: 5px; border-radius: 5px; margin-bottom: 10px;}
.activity .activity_content ul li img { margin-right: 10px; width: 40px; height: 40px; border-radius: 100%;}
.activity .activity_content ul li p { margin: 0px; font-size: 13px; color:#797979;}
.activity .activity_content ul li p span {color:#b8b8b8; font-weight: 600; margin-left: 10px;}
.chat_block .activity_hed { border-bottom: solid 1px #474747;  padding: 0 12px 10px 12px; width: 100%; display: flex; align-items: center; justify-content: space-between;}
.chat_block .activity_hed .hedText h3 { font-size: 14px; margin-bottom: 5px;}
.chat_block .activity_hed .hedText p { font-size: 14px; color: #fff; margin: 0px;}
.chat_block .activity_hed .hedText .material-icons { color: #c2c2c2; font-size: 20px; margin-right: 10px;}
.chat_block .activity_hed .wp_close { background:none; color: #989898; height: 30px;}
.chat_block .activity_hed .wp_close .material-icons { font-size: 34px; }
.chat_block .activity_content { padding: 12px 12px;}
.chat_block .activity_content ul { margin: 0px; padding: 0px;}
.chat_block .activity_content ul li { align-items: center; list-style: none; display: flex; width: 100%; margin-bottom: 10px;}
.chat_block .activity_content ul li img { margin-right: 10px; width: 40px; height: 40px; border-radius: 100%;}
.chat_block .activity_content ul li p { margin: 0px; font-size: 13px; color:#797979;}
.chat_block .activity_content ul li p span {color:#b8b8b8; margin-left: 10px;}
.chat_block .activity_content ul li p span b { margin-right: 10px;}
.add_comment {display: flex; padding: 10px 12px !important; align-items: center; margin-bottom: 5px;}
.add_comment .comment_img { margin-right: 10px; width: 40px; height: 40px; border-radius: 100%;}
.add_comment .comment_box { border-radius: 20px;}
.add_comment .comment_box::-webkit-input-placeholder { font-size: 14px;  color: #a3a3a3;}
.add_comment .comment_box::-moz-placeholder { font-size: 14px;  color: #a3a3a3;}
.add_comment .comment_box:-ms-input-placeholder {   color: #a3a3a3;}
.add_comment .comment_box:-moz-placeholder { font-size: 14px;  color: #a3a3a3;}
.add_comment .comment_post {font-size: 14px; font-size: 14px; color: #6b869d;  position: absolute;  z-index: 9999;  right: 28px;}
.donate .material-icons { margin-right: 5px;}
.name_thumb { text-transform: uppercase; margin-right: 10px; width: 40px; height: 40px; border-radius: 100%; background-color: #3072ca; color: #fff;
  font-size: 13px; display: flex; align-items: center; justify-content: center; font-weight: 600;} 
  .name_bg2 { background-color: rgb(139, 0, 23); color: #fff;    } 
  .composer input {  width: 84%;}
  .clips_tabs .react-tabs__tab-list{ display: flex; align-items: center; flex-direction: row;}
  .add_clips{ width: 100%;}

  .add_clips h6{ color:#fff; font-size: 12px; margin-bottom: 3px; font-weight: bold; margin-top: 10px; letter-spacing: 1px;}
  .add_clips input{ width: 100%; height: 42px; border-radius: 3px; background-color: #000; color:#fff;  margin-bottom: 5px;}
  .add_clips input:focus{ border:1px solid rgb(116, 116, 116);}
  .clips_tabs .react-tabs__tab{ padding: 11px!important; text-align: center; background-color: #000; color:#fff; justify-content: center; font-size: 12px; line-height: 1; font-weight: bold;}
  .clips_tabs .react-tabs__tab--selected{ color:#000; background-color: #E9C449!important;}
  .btns_group { display: flex; align-items: center;}
  .color_btns{ background-color: #E9C449; padding: 7px 15px; color: #000; font-size: 13px; font-weight: bold; line-height: 1; height: inherit; border-radius: 2px; }
  .main-stage .bitmovinplayer-container.aspect-16x9:before{ padding-bottom:28%!important}
  .subclipplayer{
    margin-bottom: 5px !important;
   }
   .chat_type{ display: flex; align-items: center; justify-content: space-between; position: relative;}
   .chime-web-composer .chat_input{ width:calc(100% - 50px); border-radius: 50px; padding-right: 50px;}
 .post_btn{ background: none; position: absolute; top: 5%; display: block; right: 16px; line-height: 1; padding: 3px; text-decoration: none!important; color: #8BB0FF!important; font-size: 13px;}
 .post_btn:hover{ text-decoration:none; cursor:pointer;}
 .approve_btns{display: flex; justify-content: space-between; align-items: flex-start;}
 .approve_btns .chat_button { width: 35px;}
 .videoError {     color: #a7a7a7; height: 150px; background-color: #101010; display: flex; align-items: center; justify-content: center;     flex-direction: column;}
 .videoError .material-icons { font-size: 30px;  margin-bottom: 10px; color: #bbb;}
 .videoError a .material-icons {color: #2968f5;}
@media screen and (max-width: 800px) {
  .chat__wrapper { margin-top: 25px;}
  .chime-web-composer { position: fixed; bottom: 0px; width: 100%;}
  .approve_btns { width: 85px;}
  .chat_actions i { font-size: 20px!important;}
  .donate {  height: 40px;}
  .approve_btns .chat_button { width: inherit !important;}
}
.startTime{
  position: relative
}
.timePickerIcon{
  position: absolute;
  bottom: 15px;
  right: 5px; 
  cursor: pointer;
}
.voting_block {

  justify-content: space-between;
  position: relative;
  z-index: 999;
  float: left;
  width: 100%;
}

.voting_block p {
  position: absolute;
  top: 3px;
  font-size: 11px;
  left: 9px;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
}
.voting_block .button {
  float: left;
  margin-bottom: 5px;
}
.voting_block .font20 {
  font-size: 14px !important;
  margin-right: 3px;
}
.donation_block_side .button, .voting_block .button {
  cursor: pointer;
  background-color: #343434;
  border: solid 1px #6b6b6b;
  border-radius: 3px;
  color: #fff;
  padding: 2px 11px;
  display: flex;
  margin-right: 5px;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  height: 25px;
}

.donation_block_side .text_box .addDonBtn {
  cursor: pointer;
  margin-left: -2px;
  background-color: #343434;
  color: #fff;
  -webkit-border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-right: 0;
  border: solid 1px #6b6b6b;
  padding: 1px 5px;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  height: 25px;
}

.donation_block_side .text_box .addDonBtn .material-icons {
  font-size: 19px;
}

.donation_block_side p {
  position: absolute;
  top: 3px;
  font-size: 11px;
  left: 9px;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
}

.donation_block_side .font20 {
  font-size: 11px !important;
  margin-right: 3px;
}
.active {
  color: #31a0ff;
}
#donationAmounts input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
#donationAmounts input:checked~.checkblock {
  background-color: #888888;
  color: #000 !important;
}
#donationAmounts, .donation_block_side, .donation_block_side .text_box{
  display: flex;
}

.donation_block_side .text_box input {
  height: 25px;
  background-color: #212222;
  border: solid 1px #6b6b6b;
  width: 60px;
  padding: 4px 6px;
  -webkit-border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 11px;
  color: #fff;
}
.poll_btn {
  cursor: pointer;
  background-color: #343434;
  border: solid 1px #6b6b6b;
  border-radius: 3px;
  color: #dcdcdc;
  padding: 6px 8px;
  display: flex;
  margin-right: 5px;
  align-items: center;
  font-size: 12px;
  width: 100%;
  justify-content: center;
}
.poll_btn .material-icons {
  font-size: 17px;
  margin-right: 5px;
  color: #fff;
}
.rt_select {
  margin-top: 10px;
}
.rt_select select {
  width: 100%;
  padding: 5px 10px;
  border: solid 1px #646464;
  background-color: #28292a;
  color: #bebebe;
  font-size: 14px;
  border-radius: 3px;
}
.rt_select h3 {
  color: #bebebe;
  font-size: 16px;
  margin: 10px 0 5px 5px;
}
/* Chrome, Safari, Edge, Opera */
#pollSessions::-webkit-outer-spin-button,
#pollSessions::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
#pollSessions[type=number] {
    -moz-appearance: textfield;
}
.rating {
  border: solid 1px #646464;
  width: 100%;
  border-radius: 5px;
  background-color: #262728;
}
.rt_hed {
  padding: 7px 15px;
  display: flex;
  justify-content: space-between;
}

.rt_hed p {
  font-size: 13px;
  margin: 0;
  color: #bebebe;
}
.total {
  border: none !important;
  margin-bottom: 0px !important;
  margin-top: -1px;
}

.total p {
  color: #fff !important;
  font-weight: bold;
}
.rt_list {
  margin: 0px !important;
  padding: 10px !important;
  background-color: #2c2d2e;
  border-radius: 3px;
}

.rt_list li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 7px 10px;
  border: solid 1px #5f5f5f;
  margin-bottom: 7px;
  border-radius: 2px;
  background-color: #2b2c2d;
}

.rt_list li p {
  color: #a4a4a4;
  font-size: 13px;
  line-height: 13px;
  margin: 0px;
}

.rt_list li p b {
  color: #fff;
  font-weight: bold;
}
.close {
  position: absolute;
  top: -15px;
  right: -10px;
  color: #fff;
  z-index: 99;
  opacity: 1 !important;
  padding: 0px;
  background: none;
  border: none;
}

.close:hover {
  opacity: 0.5;
}

.close .material-icons {
  border: solid 2px #fff;
  border-radius: 100%;
  padding: 1px;
  font-size: 15px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
.payment_block {
  right: 25px;
  top: 8%;
  position: absolute;
  border-radius: 3px;
  z-index: 99;
  padding: 15px !important;
}
      .payment_block .pmt_lft1 {     width: 100%; margin: 0px; padding: 8px; background-color: #222;}
      .payment_block .pmt_rgt1 {   width: 100%;  margin: 0px; padding: 0px; }
      .payment_block .sub-text { color: #fff; font-size: 16px;}
      .payment_block .ast_infoo1 h5 { font-size: 12px; }
      .payment_block .ast_infoo1 .row2 h5 {  width: 33%; float: left; margin-top: 6px;}
      .payment_block .ast_infoo1 h5 label { width: inherit;  font-size: 12px; margin-bottom: 0px;}
      .payment_block .ast_infoo1 h5 span {  font-size: 12px; padding-left: 10px;}
      .payment_block .pmt_blk1 { padding: 5px 0;}
      .payment_block .pmt_blk1 h3 { font-size: 16px;font-weight: 500;margin-bottom: 10px;}
      .payment_block .pmt_blk1 input[type=radio] {  width: 20px;  height: 20px;  border: 2px solid;}
      .payment_block input[type='radio']:before { width: 12px;  height: 12px;  margin: 4px;}
      .payment_block .pmt_blk1 label { font-size: 13px;  margin-bottom: 2px; font-weight: normal;}
      .payment_block .form-group {  margin-bottom: 7px;}
      .payment_block .cc_det label {  font-size: 13px;}
      .payment_block .hosted-field, .cc_det input { font-size: 13px;  padding:5px 10px !important; height: 30px !important; border: solid 1px #4a4a4a !important;
  border-radius: 2px; background-color: #1d1d1d !important;}
  .payment_block .submit { padding: 4px 35px; margin-top: 0; font-size: 13px; background-color: #565656 !important;  border: solid 1px #9e9e9e !important;
  margin-bottom: 0px !important;}
  .payment_block .ps3 { margin: 0 0 5px 0;  font-size: 13px;}
  .payment_block .pay_opt .form-group { display: flex;  align-items: center; margin-right: 20px;}
  .payment_block .pay_opt .form-group img { width: 25px;}
  .payment_block .pay_opt .col-md-12 { display: flex;}










