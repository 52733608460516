/* AUTH-0 */
.auth0-lock.auth0-lock .auth0-lock-widget-container{ margin: 0 auto;}
.u-logo-img{ z-index: 99;}
.form.auth0-lock-widget{ overflow-y: inherit!important;}
.auth0-lock.auth0-lock .auth0-lock-cred-pane{margin: 80px auto 0 auto!important; margin-bottom:0; height: auto!important; min-height: initial!important;}
form.auth0-lock-widget{ overflow-y: inherit!important; }
/* WATCHPARTY-CSS */
.logoutbtnwatch{
    position: absolute;
    right: 3rem;
    top: 6rem;
    color: #eee;
    text-transform: uppercase;
    line-height: 1;
    padding: 6px 10px;
    font-size: 11px;
    border-radius: 3px;
    border: 1px solid #eee;
}
.rounded_full{ border-radius:100%!important;line-height: 1!important;} 
.back_btn{ border-radius:3px; line-height: 1; background-color:rgb(0, 0, 0);color:#fff; padding: 5px; }
.top_left{ position: absolute; top:10px; left:10px; z-index: 9999;}
.search_block {
    position: relative;
    width: 45%;
}
.chat-line{font-size: 13px;}
.flex_display{ display: flex; align-items: center; }
.space_between{justify-content: space-between;}
.sm_btn{ border-radius: 3px;
    padding: 12px 20px;
    line-height: 1;
    font-size: 13px;
    color: #fff;
    background-color: #484848;}
.sm_btn:hover{color:#fff;}
.join_btn span{ font-size: 16px;}
.join_btn{ font-size: 13px; padding: 5px 10px; background-color: #272727; border: 1px solid #101010; border-radius: 3px; color: #c7c7c7!important;}
.col5{flex: 0 0 20%; max-width: 20%; -webkit-box-flex: 0; -ms-flex: 0 0 20%; position: relative; min-height: 1px; padding-right: 15px; padding-left: 15px;}
.wp_card{ width:100%; background-color: #171717; padding:10px;}
.wp_card .wp_head{ max-width: 100%; max-height: 100%; display: block; text-align: center; margin:0 auto;}
.wp_card .wpcard_footer p{color: #dcdcdc; margin-bottom: 0; text-shadow: 0px 0px 0px black; font-weight: bold; letter-spacing: 0.05em; -webkit-font-smoothing: subpixel-antialiased;}
.wp_card .wpcard_footer p span{ font-size: 11px;}
.wp_title{ font-size: 15px; height: 42px;}
.search_block{ position: relative; width: 50%; }
.search_block i{ position: absolute; top: 11px; left: 10px; color: #ababab; font-size: 20px;}
.search_block input{ padding-left:35px; width: 100%;}
.events_list{ padding:5px}
.wp_events{ margin-right: -5px!important; margin-left: -5px!important;}
.wp_col{ padding-left:5px!important; padding-right: 5px!important;}
.main-stage #video-player .bmpui-ui-titlebar{ bottom:0!important;}
.main-stage #video-player .bmpui-ui-titlebar #bmpui-id-115{right: 25px; position: absolute;}
.main-stage #video-player .bmpui-ui-titlebar #bmpui-id-116 { position: absolute; right: 48px; }
.bmpui-ui-skin-smallscreen .bmpui-ui-titlebar>.bmpui-container-wrapper{align-items: center;}
.col5{ -webkit-box-flex: 0; -ms-flex: 0 0 20%; flex: 0 0 20%; max-width: 20%; padding-left: 15px;
    padding-right: 15px;}

.search_block i{ position: absolute; top:10px; left:20px; color:#eee;}
.search_block input{ padding-left: 50px; border-radius: 50px; background-color: #292929; height: 36px!important;}
.search_block input:focus{ border:1px solid rgb(124, 124, 124);}
.events_list .thumbnail{ width:100%; position: relative; overflow: hidden; border-radius: 5px; margin-bottom: 30px;}
.events_list .thumbnail .thumb_overlay{ position: absolute; top:0; bottom: 0; right:0; left:0; background-color: rgba(0,0,0,0.4); display: none; padding-bottom: 10px; }
.events_list .thumbnail:hover .thumb_overlay{  display: block;}
.thumb_overlay .footer_btns{ position: absolute; bottom:5px; right:5px; left:5px; display: flex; align-items: center; justify-content: space-between;}
.thumb_overlay .footer_btns button{ width: 49%; padding: 10px 15px; line-height: 1; text-align: center; color: #fff; background-color: rgba(0,0,0,0.6); border-radius: 5px; font-size: 16px; border: 1px solid rgba(255, 255, 255, 0.521);}
.thumb_overlay .footer_btns button:hover{ cursor:pointer; background-color: rgba(0,0,0,0.8);}
.pad4_2{ padding: 4rem;}
.welcome_container{ overflow-y: auto;}
.thumb_overlay h3 {
    padding: 10px 10px;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    text-align: left;
}
.room_logo {height: auto !important; max-height: 55px; max-width: 200px;  width: auto; margin-bottom: 20px;}
@media (min-width: 321px) and (max-width: 420px){
.col5{-webkit-box-flex: 0!important; -ms-flex: 0 0 50%!important; flex: 0 0 50%!important; max-width: 50%!important;}
.pad4_2{ padding: 2rem;}
.search_block{ width:70%!important;}
.thumb_overlay .footer_btns button{
    border-radius: 2px;
    font-size: 13px;
    padding: 5px;
}
}
@media (min-width: 421px) and (max-width: 767px){
    .col5{-webkit-box-flex: 0!important; -ms-flex: 0 0 25%!important; flex: 0 0 25%!important; max-width: 25%!important;}
    .intro__inner{ padding:10px!important;}
    .pad4_2 { padding: 2rem!important; }
}
@media (min-width: 768px) and (max-width: 1023px){
    .col5{-webkit-box-flex: 0!important; -ms-flex: 0 0 33.333333333%!important; flex: 0 0 33.333333333%!important; max-width: 33.333333333%!important;}
}
@media (min-width: 300px) and (max-width: 320px){
    .col5{-webkit-box-flex: 0!important; -ms-flex: 0 0 50%!important; flex: 0 0 50%!important; max-width: 50%!important;}
    .pad4_2{ padding: 2rem;}
    .search_block{ width:70%!important;}
    .sm_btn{ padding: 10px 15px!important;}
}
@media (min-width: 1024px) and (max-width: 1450px){
    .col5{-webkit-box-flex: 0!important; -ms-flex: 0 0 25%!important; flex: 0 0 25%!important; max-width: 25%!important;
    }
    .thumb_overlay .footer_btns button{ padding: 8px!important; font-size: 14px; width: 47%;}
}
@media only screen and (max-width: 600px) {
    .events_list .thumbnail .thumb_overlay{
        display: block;
    }
  }
.iframePlayer{
    width: 100%;
    height: 65%;
    border: none;
}
.controls__btn{
    border-radius: 100% !important;
    line-height: 1 !important;
}
.bmpui-ui-titlebar>.bmpui-container-wrapper>.share{
    position: absolute;
    bottom: 20px;
    right: 70px;
    z-index: 999;
    width: 15px;
    height: 15px;
    background-size: 15px;
    background-image: url(https://envoi-common-resources.imgix.net/screening/images/dark/player_share.svg);
    background-color: transparent;
    border: none;
    cursor: pointer;
    background-repeat: no-repeat;
}
.share_modal{
    width: 100%;
    max-width: 385px!important;
    position: absolute;
    background-color: rgba(68,68,68,.5)!important;
    padding: 20px!important;
    border: 1px solid #252525!important;
    border-radius: 3px!important;
}
.share_modal .pop_hed h2{
    font-size: 20px;
    color: #dfdfdf!important;
    margin-top: -10px;
    margin-bottom: 25px;
    padding: 0;
    position: initial;
}
.share_modal .listIcon{ margin: 0 30px;}
.share_modal .u-sh-img img{
    width: 30px!important;
    padding: 0!important;
    display: inline-block!important;
}
.share_modal .u-sh-img {
    background: transparent;
}
.share_modal .pop_hed h2 button.closeBtn {
    position: absolute!important;
    left: 10px!important;
    top: 10px!important;
    opacity: 1!important;
    width: 109px;
    height: 15px;
    color: #fff;
    font-size: 14px;
    line-height: 14px;
    right: 0;
    background-color: transparent;
    width: auto;
}
.share_modal .pop_hed h2 button.closeBtn img{
    width: 14px;
    float: left;
    margin-right: 3px;
}
.share_modal .pop_hed .modalBody {
    min-height: auto!important;
    max-height: 80vh;
    overflow-y: inherit!important;
}
.share_modal .u-border-bottom {
    display: flex;
    justify-content: center;
}
.share_modal .u-share-pop-new h3{
    margin-top: 10px;
    margin-bottom: 0;
}
.share_modal .pop_hed h4{
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 2px;
    background-color: transparent;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    font-family: Avenir;
}
.share_modal .u-copy-cont {
    color: #efff00!important;
    position: absolute;
    right: 10px;
    top: 2px;
    font-weight: 500;
    font-size: 14px;
    height: auto;
    background: none;
}
.share_modal .form-control {
    padding-right: 50px!important;
    background-color: hsla(0,0%,63%,.8)!important;
    border: 1px solid #393c45!important;
    color: #fff!important;
    font-size: 12px!important;
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    border-radius: .25rem !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    height: auto !important;
}

@media (min-width: 321px) and (max-width: 767px)
{
.share_modal {
    width: 95%!important;
    padding: 10px!important;
    background-color: transparent!important;
    border: none!important;
    top: 45%!important;
}
.share_modal .u-share-pop-new .pop_hed h2 {
    font-size: 16px!important;
    color: #fff!important;
    margin-top: 0!important;
    margin-bottom: 20px!important;
}
.share_modal .u-border-bottom {
margin-top: 55px;
}
.share_modal .u-sh-img img {
    width: 27px!important;
}
}




/* Event info css */
.common_container{ width:100%; min-height: 100vh; position: relative; }
.round_backbtn{ width:42px; height: 42px; line-height: 1; display: flex; align-items: center; justify-content: center; border-radius:100%; background-color: #eee; color:#000; }
.round_backbtn i{ color:#000; font-size: 42px;}
.pos_50{position: absolute; left: 25px; top: 25px;}
.darkbluebg{ background-color: #12131A!important;}
.preview_wrapper{ width: calc(100% - 85px); margin-left: 85px; }
.preview_wrapper .preview_body{ display: flex; align-items: flex-start; flex-direction: row; width:100%; }
.preview_body .preview_img{ width:28%;}
.preview_body  .preview_details{ width:63%; margin-left:50px; padding-top: 90px;}
.preview_wrapper .preview_footer{ width:100%; margin-top:25px;}
.preview_wrapper .preview_footer .stroke_btn{ border:1px solid #eee;padding: 12px 20px; color:#eee; text-transform: uppercase; display: inline-block; font-size: 16px; }
.preview_img h1{ text-transform: uppercase; font-size: 43px; font-family: 'Avenir', sans-serif; font-weight: 800; padding-top: 20px;}
.asset_views{ justify-content: flex-start;}
.asset_views p{ margin-bottom:0; font-family: 'Avenir', sans-serif; margin-right: 120px;}
.asset_views p i, .asset_views ul li i{ color:#b3b2b2;}
.asset_views ul{ margin:0; padding: 0;}
.asset_views ul li{ font-family: 'Avenir', sans-serif; list-style: none;}
.room_title{ color:#fff; text-transform: uppercase;}
.room_features{  list-style: none; position: relative; margin-bottom:20px!important;}
.room_features li{color: #C8C8C8; font-family: 'Avenir', sans-serif; font-size: 18px;}
.room_features li:after{ content:'|'; margin-left: 5px; margin-right: 5px; }
.room_features :last-child:after{ content:''; }
.preview_details .room_title{ margin-top:35px; font-size: 28px; margin-bottom:20px!important;}
.room_desc{ font-size: 24px; color:#fff; margin-bottom:25px; line-height: 40px;}
.fillbig_btn{ padding: 10px 55px 10px 45px; line-height: 1; width:200px; font-size: 16px; justify-content: center; border-radius: 5px; text-transform: uppercase; font-weight: 300; letter-spacing: 1px; color:#fff; width: fit-content;}
.fillbig_btn:hover{  color:#fff; text-decoration: none;}
.fillbig_btn i{ font-size: 32px;}
.blue_bg{ background-color: #3072CA;}
.controls__btn span{ color:#fff}
.head_bar .u-ralign-0{
    top: 3px !important;
    right: -144px !important;
}
.bitmovinplayer-container.aspect-16x9:before {
    padding-bottom: 28%!important;
}
.btn--share_content{
    background: var(--color-bg-button);
  }
  .dropdown-toggle{
      color: #fff;
      background: none;
  }
  .selectplan_btn{
    width: -webkit-fill-available;
  }
  .back {
    background-color:transparent;
  }
  .poa_btns button {
    background-color: transparent;
    padding: 10px 15px;
    color: #fff;
    border: 1px solid #cecece;
    border-radius: 2px;
    margin: 3px;
}